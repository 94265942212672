@import "vars";

@mixin xl-screen(){
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin l-screen(){
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin m-screen(){
  @media only screen and (max-width: 960px) {
    @content;
  }
}

@mixin s-screen(){
  @media only screen and (max-width: 640px) {
    @content;
  }
}

@mixin gb-blue(){
  background-size: 200% auto;
  transition: 0.3s;
  background-image: linear-gradient(80deg, $blue 0%, #84aefd 51%, $blue 100%);
  &:hover {
    background-position: right center;
  }
}

@mixin gb-green(){
  background-size: 200% auto;
  transition: 0.3s;
  background-image: linear-gradient(80deg, $green 0%, #00eca0 51%, $green 100%);
  &:hover {
    background-position: right center;
  }
}

@mixin gb-red(){
  background-size: 200% auto;
  transition: 0.3s;
  background-image: linear-gradient(80deg, $red 0%, #f44336 51%, $red 100%);
  &:hover {
    background-position: right center;
  }
}

@mixin gb-orange(){
  background-size: 200% auto;
  transition: 0.3s;
  background-image: linear-gradient(80deg, $orange 0%, #fdd835 51%, $orange 100%);
  &:hover {
    background-position: right center;
  }
}

@mixin gb-gray($animation: true){
  background-size: 200% auto;
  transition: 0.3s;
  background-image: linear-gradient(80deg, $gray 0%, #607d8b 51%, $gray 100%);
  @if $animation == true {
    &:hover {
      background-position: right center;
    }
  }
}



