/* COLORS */
/* FONTS */
/* COLORS */
/* FONTS */
@font-face {
  font-family: "CSR_45";
  src: url(../fonts/CoreSansE/CoreSansE-45Regular.otf); }

@font-face {
  font-family: "CSM_55";
  src: url(../fonts/CoreSansE/CoreSansE-55Medium.otf); }

@font-face {
  font-family: "R_R";
  src: url(../fonts/Rubik/Rubik-Regular.ttf); }

@font-face {
  font-family: "R_M";
  src: url(../fonts/Rubik/Rubik-Medium.ttf); }

/*
@font-face {
  font-family: "CSM_65";
  src: url(../fonts/CoreSansE/CoreSansE-65Bold.otf);
}
@font-face {
  font-family: "CSM_95";
  src: url(../fonts/CoreSansE/CoreSansE-95Black.otf);
}
@font-face {
  font-family: "CSL_35";
  src: url(../fonts/CoreSansE/CoreSansE-35Light.otf);
}
@font-face {
  font-family: "CSR_45";
  src: url(../fonts/CoreSansE/CoreSansE-45Regular.otf);
}*/
body {
  font-family: "CSR_45";
  background-color: #fff;
  margin: 0;
  padding: 0;
  color: #000; }

a {
  text-decoration: none; }

b {
  font-family: "R_M"; }

img {
  max-width: 100%; }

*::-webkit-scrollbar {
  width: 5px !important;
  cursor: pointer !important;
  /*margin-left: -15px !important;*/
  background-color: transparent !important; }

*::-webkit-scrollbar-thumb {
  cursor: pointer !important;
  border-radius: 0 !important;
  background-color: rgba(38, 50, 56, 0.4) !important; }

*::-webkit-scrollbar-track {
  cursor: pointer !important;
  border-radius: 0 !important;
  background-color: transparent !important; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

.btn {
  border-radius: 25px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-size: 200% auto;
  transition: 0.3s;
  background-image: linear-gradient(80deg, #0049de 0%, #84aefd 51%, #0049de 100%); }
  .btn:hover {
    background-position: right center; }
  .btn:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .btn img {
    width: 21px;
    margin-right: 10px; }

.section .container {
  width: 80%; }
  @media only screen and (max-width: 1280px) {
    .section .container {
      width: 86%; } }
  @media only screen and (max-width: 960px) {
    .section .container {
      width: 94%; } }

.section.header {
  border-top: 3px solid #00a762;
  font-family: "CSM_55";
  padding: 28px 0;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  z-index: 1; }
  .section.header .container {
    display: table; }
  .section.header .menu ul {
    display: inline-block;
    float: left;
    margin: 0; }
    @media only screen and (max-width: 960px) {
      .section.header .menu ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 15px; } }
    .section.header .menu ul li {
      float: left;
      margin-right: 20px; }
      @media only screen and (max-width: 960px) {
        .section.header .menu ul li {
          margin: 0; } }
      .section.header .menu ul li a {
        display: block;
        padding: 10px 15px;
        color: #000;
        font-size: 18px;
        line-height: 1.1; }
      .section.header .menu ul li:first-child a {
        background: #00a762;
        color: #fff;
        border-radius: 25px;
        background-size: 200% auto;
        transition: 0.3s;
        background-image: linear-gradient(80deg, #00a762 0%, #00eca0 51%, #00a762 100%); }
        .section.header .menu ul li:first-child a:hover {
          background-position: right center; }
        .section.header .menu ul li:first-child a img {
          width: 21px;
          float: left;
          margin-right: 4px; }
  .section.header .login, .section.header .logout {
    float: right;
    background-color: #0049de;
    line-height: 1.1;
    height: auto;
    font-size: 19px;
    padding: 10px 15px;
    text-transform: capitalize;
    border-radius: 25px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-size: 200% auto;
    transition: 0.3s;
    background-image: linear-gradient(80deg, #0049de 0%, #84aefd 51%, #0049de 100%); }
    .section.header .login:hover, .section.header .logout:hover {
      background-position: right center; }
    .section.header .login.profile, .section.header .logout.profile {
      background-size: 200% auto;
      transition: 0.3s;
      background-image: linear-gradient(80deg, #00a762 0%, #00eca0 51%, #00a762 100%); }
      .section.header .login.profile:hover, .section.header .logout.profile:hover {
        background-position: right center; }
    .section.header .login img, .section.header .logout img {
      width: 21px;
      float: left;
      margin-right: 10px; }
  .section.header .logout {
    margin-left: 8px;
    padding: 11px;
    background-size: 200% auto;
    transition: 0.3s;
    background-image: linear-gradient(80deg, #37474f 0%, #607d8b 51%, #37474f 100%); }
    .section.header .logout:hover {
      background-position: inherit; }
    .section.header .logout img {
      width: 18px;
      margin: 0; }

.section.slide {
  padding: 0;
  z-index: 2;
  position: relative; }
  .section.slide img {
    max-width: 100%;
    width: 100%; }
  .section.slide .welcome {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    position: absolute;
    bottom: -60px;
    left: 50%;
    z-index: 9;
    margin-left: -30%;
    background: #fff;
    font-size: 18px;
    padding: 40px;
    -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.09); }
    @media only screen and (max-width: 1280px) {
      .section.slide .welcome {
        bottom: 120px; } }
    @media only screen and (max-width: 960px) {
      .section.slide .welcome {
        width: 76%;
        margin-left: -38%;
        bottom: 184px;
        padding: 30px; } }
    .section.slide .welcome p {
      margin: 0;
      width: 50%;
      font-family: "R_R";
      line-height: 1.3; }
      @media only screen and (max-width: 960px) {
        .section.slide .welcome p {
          width: 55%; } }
      .section.slide .welcome p span {
        color: #0049de; }
    .section.slide .welcome .btn {
      border-radius: 25px;
      background: #0049de;
      font-size: 18px;
      font-family: "R_M";
      line-height: 46px;
      height: auto;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background-size: 200% auto;
      transition: 0.3s;
      background-image: linear-gradient(80deg, #0049de 0%, #84aefd 51%, #0049de 100%); }
      .section.slide .welcome .btn:hover {
        background-position: right center; }

.section.breadcrumb__content {
  padding: 30px 0 0 0; }

.section.content .title {
  font-family: "CSR_45";
  font-weight: normal;
  font-size: 30px;
  text-align: center;
  position: relative;
  margin: 0; }

.section.content.about {
  padding-top: 100px;
  font-family: "R_M"; }
  .section.content.about h3 {
    font-size: 20px;
    text-align: center;
    margin: 40px 0 30px 0; }
  .section.content.about .only__school .col h5 {
    font-size: 18px;
    margin: 30px 0;
    padding-right: 0;
    padding-left: 70px;
    line-height: 2.2; }
    @media only screen and (max-width: 1280px) {
      .section.content.about .only__school .col h5 {
        font-size: 16px; } }
    @media only screen and (max-width: 960px) {
      .section.content.about .only__school .col h5 {
        width: 50%;
        float: left;
        text-align: center !important;
        position: relative;
        padding: 45px 0 0 0 !important; } }
    .section.content.about .only__school .col h5 img {
      width: 40px;
      float: left;
      margin-left: -70px;
      margin-right: 0; }
      @media only screen and (max-width: 960px) {
        .section.content.about .only__school .col h5 img {
          position: absolute;
          left: 50%;
          margin-left: -20px !important;
          top: 0; } }
  .section.content.about .only__school .col:first-child {
    padding: 0; }
    .section.content.about .only__school .col:first-child h5 {
      text-align: right;
      padding-right: 70px;
      padding-left: 0; }
      .section.content.about .only__school .col:first-child h5 img {
        float: right;
        margin-right: -70px;
        margin-left: 0; }
  .section.content.about .only__school .col:last-child {
    padding: 0; }
  @media only screen and (max-width: 960px) {
    .section.content.about .only__school .col:nth-child(2) img {
      width: 45%;
      display: block;
      margin: 0 auto; } }
  .section.content.about .about__school {
    padding-top: 30px; }
    .section.content.about .about__school .col {
      position: relative; }
      .section.content.about .about__school .col:first-child {
        padding-left: 0; }
      .section.content.about .about__school .col:last-child {
        padding-right: 0; }
        .section.content.about .about__school .col:last-child h5 {
          text-align: center;
          position: absolute;
          width: 100%;
          top: 26%;
          font-family: "R_M";
          font-size: 18px; }
        .section.content.about .about__school .col:last-child p {
          text-align: center;
          position: absolute;
          width: 82%;
          margin-left: -36%;
          left: 49%;
          top: 37%;
          font-family: "R_R";
          font-size: 15px; }
        .section.content.about .about__school .col:last-child img {
          float: right; }
      .section.content.about .about__school .col img {
        float: left; }
      @media only screen and (max-width: 960px) {
        .section.content.about .about__school .col {
          padding: 0; } }

.section.content.comments {
  padding-top: 50px; }
  .section.content.comments .owl-carousel {
    margin: 40px 0; }
    .section.content.comments .owl-carousel .owl-stage-outer {
      height: auto;
      max-height: 235px; }
    .section.content.comments .owl-carousel .owl-item {
      width: 420px !important;
      padding: 24px;
      margin-bottom: 20px;
      margin-top: 20px;
      font-family: "R_R";
      font-size: 16px;
      -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.08); }
      .section.content.comments .owl-carousel .owl-item h3 {
        font-family: "R_M";
        font-size: 16px;
        margin: 0; }
      .section.content.comments .owl-carousel .owl-item p {
        height: 72px;
        overflow: hidden;
        overflow-y: auto; }
      .section.content.comments .owl-carousel .owl-item.center {
        text-align: left; }

.section.content.registration .block {
  padding-top: 20px;
  min-height: 600px;
  position: relative;
  overflow: hidden; }
  .section.content.registration .block h6 {
    text-align: center;
    font-size: 15px;
    font-weight: normal; }
  .section.content.registration .block img.arrow {
    width: 100px;
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 9; }
    .section.content.registration .block img.arrow:nth-child(2) {
      right: 0;
      left: inherit;
      transform: scaleX(-1); }
  .section.content.registration .block img.iphone {
    width: 27%;
    position: absolute;
    right: 17%;
    top: 14%;
    z-index: 1;
    border-top-left-radius: 51px;
    border-top-right-radius: 51px;
    box-shadow: 0 0 90px 12px rgba(0, 0, 0, 0.08); }
    @media only screen and (max-width: 960px) {
      .section.content.registration .block img.iphone {
        width: 34%; } }
  .section.content.registration .block form {
    width: 380px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    background-color: #FFFFFF;
    margin-left: -190px;
    margin-top: -116px;
    text-align: center;
    overflow: hidden;
    padding: 24px 40px;
    border-radius: 20px;
    box-shadow: 0 0 90px 12px rgba(0, 0, 0, 0.08); }
    .section.content.registration .block form h4 {
      font-size: 22px;
      margin-top: 10px; }
    .section.content.registration .block form .input-field {
      margin: 24px 0; }
    .section.content.registration .block form input {
      width: 100%;
      box-sizing: border-box;
      max-width: 100%;
      border: 1px solid #0049de;
      border-radius: 8px;
      padding: 10px 10px;
      line-height: 1;
      height: auto;
      font-size: 27px;
      letter-spacing: 3px;
      margin: 0; }
      .section.content.registration .block form input:focus {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; }
    .section.content.registration .block form button {
      background: #0049de;
      background-size: 200% auto;
      transition: 0.3s;
      background-image: linear-gradient(80deg, #0049de 0%, #84aefd 51%, #0049de 100%);
      font-size: 22px;
      text-transform: capitalize;
      line-height: 2;
      height: auto;
      color: #FFFFFF;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
      .section.content.registration .block form button:hover {
        background-position: right center; }
      .section.content.registration .block form button img {
        float: right;
        width: 14px;
        height: 44px !important;
        display: block;
        margin-left: 8px; }
  .section.content.registration .block .contact {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: #FFFFFF;
    overflow: hidden;
    padding: 20px 0; }
    .section.content.registration .block .contact h3 {
      width: 58%;
      margin: 0;
      float: left;
      font-size: 30px; }
      @media only screen and (max-width: 1280px) {
        .section.content.registration .block .contact h3 {
          font-size: 26px; } }
    .section.content.registration .block .contact a.btn {
      background: #0049de;
      background-size: 200% auto;
      transition: 0.3s;
      background-image: linear-gradient(80deg, #0049de 0%, #84aefd 51%, #0049de 100%);
      padding: 10px 20px 10px 10px;
      text-transform: lowercase;
      box-shadow: none;
      line-height: 1.2;
      border-radius: 30px;
      float: right;
      font-size: 30px;
      height: auto;
      font-family: "R_R"; }
      .section.content.registration .block .contact a.btn:hover {
        background-position: right center; }
      @media only screen and (max-width: 1280px) {
        .section.content.registration .block .contact a.btn {
          line-height: 1.4; } }
      @media only screen and (max-width: 1280px) {
        .section.content.registration .block .contact a.btn {
          font-size: 26px; } }
      .section.content.registration .block .contact a.btn img {
        width: 38px;
        background: #fff;
        border-radius: 26px;
        border: 1px solid #fff;
        float: left;
        margin-right: 10px; }

.section.content.page {
  padding: 60px 0;
  min-height: 70vh; }
  .section.content.page .container {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    padding: 40px; }
  .section.content.page .title {
    margin-bottom: 40px; }

.section.content.subjects {
  padding: 60px 0;
  min-height: 70vh; }
  .section.content.subjects .container {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    padding: 40px; }
  .section.content.subjects .title {
    margin-bottom: 20px;
    text-align: left;
    line-height: 42px; }
    .section.content.subjects .title ul {
      display: block;
      margin: 0px;
      float: right;
      overflow: hidden;
      border-radius: 24px;
      border: 1px solid #00a762; }
      .section.content.subjects .title ul li {
        float: left; }
        .section.content.subjects .title ul li.active {
          background: #00a762; }
          .section.content.subjects .title ul li.active a {
            color: #ffffff; }
        .section.content.subjects .title ul li a {
          display: block;
          padding: 12px 14px;
          font-size: 16px;
          line-height: 1;
          color: #000; }
  .section.content.subjects .subtitle {
    font-size: 22px;
    color: #616161;
    margin: 40px 0 15px 0; }
  .section.content.subjects article {
    display: flex;
    align-items: center;
    border: 2px solid #c6c6c6;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 24px; }
    .section.content.subjects article img {
      width: 100px;
      height: 100px;
      margin: 0 5px; }
    .section.content.subjects article div {
      flex: 1 1 auto;
      padding: 10px 15px; }
      .section.content.subjects article div h3 {
        margin: 0;
        height: 26px;
        overflow: hidden;
        font-size: 24px;
        max-width: 100%; }
        .section.content.subjects article div h3 a {
          color: #000; }
      .section.content.subjects article div h5 {
        margin: 5px 0;
        color: #616161;
        font-size: 16px; }
      .section.content.subjects article div p {
        margin: 0;
        font-size: 16px; }
    .section.content.subjects article .btn {
      padding: 0 18px;
      color: #fff;
      margin: 0 15px;
      font-size: 24px;
      height: auto;
      line-height: 46px;
      background: #00a762; }
      .section.content.subjects article .btn img {
        float: left;
        width: 24px;
        height: 46px;
        margin-right: 15px; }
    .section.content.subjects article aside {
      width: 210px;
      box-sizing: border-box;
      text-align: right; }
      .section.content.subjects article aside .btn {
        text-align: left;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        font-size: 20px;
        line-height: 38px;
        margin: 0; }
        .section.content.subjects article aside .btn + .btn {
          margin-top: 10px; }
        .section.content.subjects article aside .btn img {
          height: 38px; }

.section.content.subject {
  padding: 30px 0 60px 0; }
  .section.content.subject .title {
    margin-bottom: 40px; }

.section.content.lessons {
  padding: 30px 0 50px 0;
  min-height: 70vh;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  align-items: stretch; }
  .section.content.lessons .container {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    padding: 40px; }
  .section.content.lessons .title {
    margin-bottom: 40px; }
  .section.content.lessons .lesson {
    padding: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    border: 1px solid #b8c6e1;
    border-radius: 5px;
    display: flex; }
    .section.content.lessons .lesson img {
      width: 20px;
      height: 20px; }
    .section.content.lessons .lesson h2 {
      font-family: "R_R";
      font-size: 20px;
      line-height: 1;
      margin: 0;
      padding-left: 15px;
      flex: 1 1 auto; }
      .section.content.lessons .lesson h2 a {
        display: block;
        color: #000000; }
  .section.content.lessons .content h1 {
    font-size: 32px;
    margin: 0 0 24px 0; }
    .section.content.lessons .content h1 .btn {
      float: left;
      margin-right: 10px;
      background: #ffffff;
      border: 1px solid #001a65;
      color: #001a65; }
      .section.content.lessons .content h1 .btn img {
        height: 32px;
        float: left; }
  .section.content.lessons .content .audio {
    margin-bottom: 24px; }
    .section.content.lessons .content .audio span {
      padding-left: 15px;
      display: block; }
    .section.content.lessons .content .audio audio {
      width: 100%; }
  .section.content.lessons .content .video span {
    padding-left: 15px;
    display: block;
    text-align: center; }
  .section.content.lessons .content .video video {
    display: block;
    margin: 0 auto; }
  .section.content.lessons .content .contents {
    text-align: justify;
    margin-bottom: 40px; }
  .section.content.lessons .pagination .btn {
    float: left;
    margin-right: 10px;
    background: #ffffff;
    border: 1px solid #001a65;
    color: #001a65; }
    .section.content.lessons .pagination .btn.right {
      float: right;
      margin-right: 0; }
      .section.content.lessons .pagination .btn.right img {
        margin-left: 10px;
        margin-right: 0;
        float: right; }
    .section.content.lessons .pagination .btn img {
      height: 32px;
      float: left; }

.section.content #comments {
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid #d6d6d6; }
  .section.content #comments h4 {
    font-size: 24px;
    margin: 0; }
  .section.content #comments .list {
    overflow: hidden;
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 24px 0;
    padding: 24px;
    border: 1px solid #d6d6d6; }
    .section.content #comments .list p {
      display: block;
      width: 100%;
      margin: 0;
      padding: 5px 0;
      text-align: left; }
      .section.content #comments .list p span {
        display: inline-block;
        font-size: 16px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
        max-width: 60%;
        padding: 8px 12px;
        border-radius: 24px; }
      .section.content #comments .list p.right {
        text-align: right; }
  .section.content #comments textarea, .section.content #comments textarea:focus {
    padding: 15px 24px;
    border-radius: 24px;
    border: 1px solid #d6d6d6;
    outline: none;
    box-shadow: none; }
  .section.content #comments .btn {
    float: right; }

.section.content.audiobooks {
  padding: 60px 0; }
  .section.content.audiobooks .container {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    padding: 40px; }
  .section.content.audiobooks .title {
    margin-bottom: 50px; }
  .section.content.audiobooks aside {
    box-sizing: border-box;
    padding: 24px;
    margin-bottom: 50px;
    display: flex;
    align-items: stretch;
    border-radius: 8px;
    border: 2px solid #c6c6c6; }
    .section.content.audiobooks aside img:first-child {
      width: 70px;
      height: 70px;
      display: block;
      margin-right: 24px; }
    .section.content.audiobooks aside div {
      flex: 1 1 auto; }
      .section.content.audiobooks aside div h2 {
        font-family: "CSR_45";
        font-size: 24px;
        margin: 0; }
        .section.content.audiobooks aside div h2 a {
          color: #002879; }
      .section.content.audiobooks aside div h6 {
        display: block;
        font-family: "CSR_45";
        font-size: 14px;
        color: #bbbbbb;
        margin: 0; }
    .section.content.audiobooks aside .btn {
      margin: 17px auto; }
      .section.content.audiobooks aside .btn.btn-success {
        background-size: 200% auto;
        transition: 0.3s;
        background-image: linear-gradient(80deg, #00a762 0%, #00eca0 51%, #00a762 100%); }
        .section.content.audiobooks aside .btn.btn-success:hover {
          background-position: right center; }
      .section.content.audiobooks aside .btn img {
        width: 20px;
        height: 36px;
        margin-right: 10px;
        float: left; }
    .section.content.audiobooks aside span {
      position: absolute; }

.section.content.buy {
  padding: 60px 0;
  min-height: 70vh; }
  .section.content.buy .title {
    margin-bottom: 20px; }
  .section.content.buy article {
    display: flex;
    justify-content: center;
    padding: 50px 40px; }
    .section.content.buy article form:not(#buy__audiobooks) {
      width: 50%;
      box-shadow: 0 0 70px rgba(0, 0, 0, 0.04);
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 40px; }
      .section.content.buy article form:not(#buy__audiobooks) .stars {
        display: flex;
        justify-content: space-around;
        position: relative;
        box-sizing: border-box; }
        .section.content.buy article form:not(#buy__audiobooks) .stars img {
          width: 100%;
          height: 80px; }
      .section.content.buy article form:not(#buy__audiobooks) h2 {
        width: 100%;
        font-size: 22px;
        text-align: center;
        margin-top: 24px;
        font-family: "R_M";
        text-transform: uppercase; }
      .section.content.buy article form:not(#buy__audiobooks) .info {
        margin-bottom: auto; }
        .section.content.buy article form:not(#buy__audiobooks) .info span {
          display: block;
          font-size: 16px; }
      .section.content.buy article form:not(#buy__audiobooks) .price {
        margin-bottom: 0;
        margin-top: 25px;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        font-family: "R_M"; }
      .section.content.buy article form:not(#buy__audiobooks) select {
        height: 2.5rem;
        margin: 0 0 10px 0; }
      .section.content.buy article form:not(#buy__audiobooks) .btn {
        display: block;
        margin: 15px auto 0 auto;
        background-image: linear-gradient(80deg, #662d91 0%, #00c9dd 51%, #662d91 100%); }
        .section.content.buy article form:not(#buy__audiobooks) .btn img {
          height: 36px;
          float: left; }
  .section.content.buy form#buy__audiobooks {
    display: flex;
    align-items: center; }
    .section.content.buy form#buy__audiobooks .info {
      flex: 1 1 auto; }
      .section.content.buy form#buy__audiobooks .info h2 {
        margin: 0;
        font-size: 28px; }
      .section.content.buy form#buy__audiobooks .info p {
        margin: 0;
        font-size: 16px;
        color: rgba(69, 69, 69, 0.49); }
    .section.content.buy form#buy__audiobooks .price {
      margin: 0 10px;
      font-size: 18px; }
    .section.content.buy form#buy__audiobooks select {
      width: 100px;
      height: auto;
      margin: 0 24px; }
    .section.content.buy form#buy__audiobooks .btn {
      width: 150px; }
      .section.content.buy form#buy__audiobooks .btn img {
        height: 36px;
        float: left; }

.section.content.auth {
  padding: 50px 0;
  min-height: 70vh;
  display: flex;
  align-items: center; }
  .section.content.auth .container {
    box-shadow: none;
    padding: 0; }
  .section.content.auth form {
    width: 35%;
    padding: 24px 32px;
    background: #FFFFFF;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
    margin: 0 auto; }
    .section.content.auth form h2 {
      margin: 0 0 20px 0;
      text-align: center;
      font-size: 28px; }
    .section.content.auth form .btn, .section.content.auth form .btn-flat {
      height: auto;
      line-height: 40px;
      font-size: 18px;
      text-transform: inherit;
      text-align: center; }
  .section.content.auth.login .btn, .section.content.auth.login .btn-flat {
    width: 64%; }
  .section.content.auth.login .btn {
    width: 34%; }
  .section.content.auth.login a:not(.btn-flat) {
    display: block;
    padding: 15px 0 0 0;
    text-align: center; }
  .section.content.auth.registration .btn, .section.content.auth.registration .btn-flat {
    width: 34%; }
  .section.content.auth.registration .btn {
    width: 64%; }

.section.content.profile {
  padding: 50px 0;
  min-height: 70vh; }
  .section.content.profile .container {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    padding: 40px; }
  .section.content.profile ul {
    border-bottom: 1px solid #dcdcdc;
    padding: 0;
    margin: 35px 0 15px 0; }
    .section.content.profile ul li {
      float: left;
      border-bottom: 2px solid transparent;
      margin-bottom: -1px; }
      .section.content.profile ul li.active {
        border-color: #0035a0; }
        .section.content.profile ul li.active a {
          color: #0035a0; }
      .section.content.profile ul li a {
        display: block;
        padding: 10px 25px;
        color: #000000;
        font-size: 16px; }
        .section.content.profile ul li a:hover {
          color: #0035a0; }
  .section.content.profile .settings {
    padding: 24px 0; }
    .section.content.profile .settings input {
      border-color: #0035a0 !important; }
    .section.content.profile .settings label {
      color: #0035a0 !important; }
  .section.content.profile .subjects__list .item, .section.content.profile .audiobooks__list .item {
    padding: 15px;
    display: flex;
    background: rgba(0, 0, 0, 0.01);
    margin-bottom: 15px; }
    .section.content.profile .subjects__list .item img, .section.content.profile .audiobooks__list .item img {
      width: 80px;
      height: 80px;
      margin-right: 15px; }
    .section.content.profile .subjects__list .item h2, .section.content.profile .audiobooks__list .item h2 {
      position: relative;
      font-size: 28px;
      margin: 0;
      flex: 1 1 auto; }
      .section.content.profile .subjects__list .item h2 a, .section.content.profile .audiobooks__list .item h2 a {
        color: #000000;
        display: block; }
      .section.content.profile .subjects__list .item h2[data-sub-name], .section.content.profile .audiobooks__list .item h2[data-sub-name] {
        padding-top: 25px; }
        .section.content.profile .subjects__list .item h2[data-sub-name]:before, .section.content.profile .audiobooks__list .item h2[data-sub-name]:before {
          content: attr(data-sub-name);
          position: absolute;
          top: 0;
          left: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.36); }
  .section.content.profile .subjects__list article, .section.content.profile .audiobooks__list article {
    display: flex;
    align-items: center;
    border: 2px solid #c6c6c6;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 24px; }
    .section.content.profile .subjects__list article img, .section.content.profile .audiobooks__list article img {
      width: 100px;
      height: 100px;
      margin: 0 5px; }
    .section.content.profile .subjects__list article div, .section.content.profile .audiobooks__list article div {
      flex: 1 1 auto;
      padding: 10px 15px; }
      .section.content.profile .subjects__list article div h3, .section.content.profile .audiobooks__list article div h3 {
        margin: 0;
        height: 26px;
        overflow: hidden;
        font-size: 24px;
        max-width: 100%; }
        .section.content.profile .subjects__list article div h3 a, .section.content.profile .audiobooks__list article div h3 a {
          color: #000; }
      .section.content.profile .subjects__list article div h5, .section.content.profile .audiobooks__list article div h5 {
        margin: 5px 0;
        color: #616161;
        font-size: 16px; }
      .section.content.profile .subjects__list article div p, .section.content.profile .audiobooks__list article div p {
        margin: 0;
        font-size: 16px; }
    .section.content.profile .subjects__list article .btn, .section.content.profile .audiobooks__list article .btn {
      padding: 0 18px;
      color: #fff;
      margin: 0 15px;
      font-size: 24px;
      height: auto;
      line-height: 46px;
      background: #00a762; }
      .section.content.profile .subjects__list article .btn img, .section.content.profile .audiobooks__list article .btn img {
        float: left;
        width: 24px;
        height: 46px;
        margin-right: 15px; }

.section.content.error__page {
  min-height: 70vh; }
  .section.content.error__page .container {
    box-shadow: none; }
  .section.content.error__page svg {
    width: 38%;
    margin: 10px auto 0 auto;
    display: block; }

.section.footer {
  padding: 24px 0; }
  .section.footer h6 {
    font-size: 18px;
    text-align: center;
    margin: 15px 0; }

.section .input__rounded input, .section .input__rounded select, .section .input__rounded select {
  border: 1px solid #0049de !important;
  padding: 0 20px;
  border-radius: 25px;
  height: auto;
  line-height: 2.8;
  z-index: 1;
  box-sizing: border-box; }
  .section .input__rounded input:focus, .section .input__rounded select:focus, .section .input__rounded select:focus {
    box-shadow: none !important; }

.section .input__rounded select {
  height: 46px; }
  .section .input__rounded select:focus {
    outline: none !important; }
  .section .input__rounded select + label:after {
    position: absolute;
    left: 0; }

.section .input__rounded label, .section .input__rounded label.active {
  top: -9px;
  left: 20px;
  color: #0049de !important;
  padding: 0 5px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  transform: none !important;
  transform-origin: inherit !important;
  z-index: 5; }
  .section .input__rounded label:after, .section .input__rounded label.active:after {
    content: '';
    top: 9px !important;
    background: #fff !important;
    height: 1px;
    width: 100%;
    z-index: -1;
    opacity: 1 !important; }

.section .input__rounded.col label {
  left: 31px; }

.section .btn.btn-rounded {
  box-shadow: none;
  border-radius: 25px;
  background-size: 200% auto;
  transition: 0.3s;
  background-image: linear-gradient(80deg, #0049de 0%, #84aefd 51%, #0049de 100%); }
  .section .btn.btn-rounded:hover {
    background-position: right center; }

.section .btn-flat.btn-rounded {
  box-shadow: none;
  border-radius: 25px; }

.pagination.center li {
  overflow: hidden; }
  .pagination.center li.active {
    background: #0049de; }
  .pagination.center li.prev span, .pagination.center li.next span {
    display: block;
    padding: 0 10px; }
    .pagination.center li.prev span img, .pagination.center li.next span img {
      display: block;
      width: 16px;
      height: 30px; }
  .pagination.center li.prev a img, .pagination.center li.next a img {
    display: block;
    width: 16px;
    height: 30px; }

.breadcrumb-list .breadcrumb {
  color: #9a9a9a;
  font-size: 14px;
  text-transform: uppercase; }
  .breadcrumb-list .breadcrumb:first-child:before {
    display: none !important; }
  .breadcrumb-list .breadcrumb:last-child {
    color: #9a9a9a; }
  .breadcrumb-list .breadcrumb:before {
    content: '';
    display: inline-block;
    height: 13px;
    margin: 0 3px -1px 0;
    padding: 4px 10px;
    background: url(/img/chevron-right.svg) no-repeat center;
    vertical-align: baseline; }

.btn.buy {
  margin: 0 auto 40px auto;
  line-height: 48px;
  height: auto;
  font-size: 24px; }
  .btn.buy img {
    width: 24px;
    height: 48px;
    display: block;
    float: left; }

.text-center {
  text-align: center; }

.datepicker {
  background: #314048;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  border: none; }

.datepicker--nav {
  max-height: 32px;
  box-sizing: border-box;
  background: #263238;
  padding: 0; }

.datepicker--pointer {
  background: #263238;
  border: none; }

.datepicker--nav-title i {
  font-size: 16px !important;
  color: #fff; }

.btlr {
  border-top-left-radius: 0 !important; }

.btrr {
  border-top-right-radius: 0 !important; }

.bblr {
  border-bottom-left-radius: 0 !important; }

.bbrr {
  border-bottom-right-radius: 0 !important; }

[class*=hint--]:after {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08); }

.noty_theme__metroui.noty_bar {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19); }

.noty_theme__metroui.noty_type__error {
  background-color: #f44336; }

.noty_theme__metroui.noty_type__warning {
  background-color: #ffa726; }

.noty_theme__metroui.noty_type__info, .noty_theme__metroui.noty_type__information {
  background-color: #00bcd4; }

.noty_theme__metroui.noty_type__success {
  background-color: #00a762; }

.noty_theme__metroui.noty_bar .noty_body {
  letter-spacing: 0.5px; }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }
