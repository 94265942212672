@import "inc/mixin";
@import "inc/vars";
@import "inc/fonts";

body{
  font-family: $font_regular_45;
  background-color: #fff;
  margin: 0;
  padding: 0;
  color: #000;
}
a{
  text-decoration: none;
}
b{
  font-family: $font_rubik_medium;
}
img{
  max-width: 100%;
}
*::-webkit-scrollbar{
  width: 5px !important;
  cursor: pointer !important;
  /*margin-left: -15px !important;*/
  background-color: transparent !important;
}
*::-webkit-scrollbar-thumb{
  cursor: pointer !important;
  border-radius: 0 !important;
  background-color: rgba(38, 50, 56, 0.4) !important;
}
*::-webkit-scrollbar-track{
  cursor: pointer !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}
h1,h2,h3,h4,h5,h6{
  font-weight: normal;
}
.btn{
  border-radius: 25px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  @include gb-blue;
  &:hover{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  & img{
    width: 21px;
    margin-right: 10px;
  }
}

.section{
  & .container{
    width: 80%;
    @include l-screen{
      width: 86%;
    }
    @include m-screen{
      width: 94%;
    }
  }
  &.header{
    border-top: 3px solid $green;
    font-family: $font_medium_55;
    padding: 28px 0;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    z-index: 1;
    & .container{
      display: table;
    }
    & .menu{
      & ul{
        display: inline-block;
        float: left;
        margin: 0;
        @include m-screen{
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 15px;
        }
        & li{
          float: left;
          margin-right: 20px;
          @include m-screen{
            margin: 0;
          }
          & a{
            display: block;
            padding: 10px 15px;
            color: #000;
            font-size: 18px;
            line-height: 1.1;
          }
          &:first-child a{
            background: $green;
            color: #fff;
            border-radius: 25px;
            @include gb-green;
            & img{
              width: 21px;
              float: left;
              margin-right: 4px;
            }
          }
        }
      }
    }
    & .login, .logout{
      float: right;
      background-color: #0049de;
      line-height: 1.1;
      height: auto;
      font-size: 19px;
      padding: 10px 15px;
      text-transform: capitalize;
      border-radius: 25px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      @include gb-blue;
      &.profile{
        @include gb-green;
      }
      & img{
        width: 21px;
        float: left;
        margin-right: 10px;
      }
    }
    & .logout{
      margin-left: 8px;
      padding: 11px;
      @include gb-gray(false);
      &:hover{
        background-position: inherit;
      }
      & img{
        width: 18px;
        margin: 0;
      }
    }
  }
  &.slide{
    padding: 0;
    z-index: 2;
    position: relative;
    & img{
      max-width: 100%;
      width: 100%;
    }
    & .welcome{
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 15px;
      position: absolute;
      bottom: -60px;
      left: 50%;
      z-index: 9;
      margin-left: -30%;
      background: #fff;
      font-size: 18px;
      padding: 40px;
      -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.09);
      @include l-screen{
        bottom: 120px;
      }
      @include m-screen{
        width: 76%;
        margin-left: -38%;
        bottom: 184px;
        padding: 30px;
      }
      & p{
        margin: 0;
        width: 50%;
        font-family: $font_rubik_regular;
        line-height: 1.3;
        @include m-screen{
          width: 55%;
        }
        & span{
          color: $blue;
        }
      }
      & .btn{
        border-radius: 25px;
        background: $blue;
        font-size: 18px;
        font-family: $font_rubik_medium;
        line-height: 46px;
        height: auto;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        @include gb-blue;
      }
    }
  }
  &.breadcrumb__content{
    padding: 30px 0 0 0;
  }
  &.content{
    & .title{
      font-family: $font_regular_45;
      font-weight: normal;
      font-size: 30px;
      text-align: center;
      position: relative;
      margin: 0;
    }
    &.about{
      padding-top: 100px;
      font-family: $font_rubik_medium;
      & h3{
        font-size: 20px;
        text-align: center;
        margin: 40px 0 30px 0;
      }
      & .only__school{
        & .col{
          & h5{
            font-size: 18px;
            @include l-screen{
              font-size: 16px;
            }
            margin: 30px 0;
            padding-right: 0;
            padding-left: 70px;
            line-height: 2.2;
            @include m-screen{
              width: 50%;
              float: left;
              text-align: center !important;
              position: relative;
              padding: 45px 0 0 0 !important;
            }
            & img{
              width: 40px;
              float: left;
              margin-left: -70px;
              margin-right: 0;
              @include m-screen{
                position: absolute;
                left: 50%;
                margin-left: -20px !important;
                top: 0;
              }
            }
          }
          &:first-child{
            padding: 0;
            & h5{
              text-align: right;
              padding-right: 70px;
              padding-left: 0;
              & img{
                float: right;
                margin-right: -70px;
                margin-left: 0;
              }
            }
          }
          &:last-child{
            padding: 0;
          }
          &:nth-child(2){
            & img{
              @include m-screen{
                width: 45%;
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
      & .about__school{
        padding-top: 30px;
        & .col{
          position: relative;
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
            & h5{
              text-align: center;
              position: absolute;
              width: 100%;
              top: 26%;
              font-family: $font_rubik_medium;
              font-size: 18px;
            }
            & p{
              text-align: center;
              position: absolute;
              width: 82%;
              margin-left: -36%;
              left: 49%;
              top: 37%;
              font-family: $font_rubik_regular;
              font-size: 15px;
            }
            & img{
              float: right;
            }
          }
          & img{
            float: left;
          }
          @include m-screen{
            padding: 0;
          }
        }
      }
    }
    &.comments{
      padding-top: 50px;
      & .owl-carousel{
        margin: 40px 0;
        & .owl-stage-outer{
          height: auto;
          max-height: 235px;
        }
        & .owl-item{
          width: 420px !important;
          padding: 24px;
          margin-bottom: 20px;
          margin-top: 20px;
          font-family: $font_rubik_regular;
          font-size: 16px;
          -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
          & h3{
            font-family: $font_rubik_medium;
            font-size: 16px;
            margin: 0;
          }
          & p{
            height: 72px;
            overflow: hidden;
            overflow-y: auto;
          }
          &.center{
            text-align: left;
          }
        }
      }
    }
    &.registration{
      & .block{
        padding-top: 20px;
        min-height: 600px;
        position: relative;
        overflow: hidden;
        & h6{
          text-align: center;
          font-size: 15px;
          font-weight: normal;;
        }
        & img{
          &.arrow{
            width: 100px;
            position: absolute;
            top: -20px;
            left: 0;
            z-index: 9;
            &:nth-child(2){
              right: 0;
              left: inherit;
              transform: scaleX(-1);
            }
          }
          &.iphone{
            width: 27%;
            @include m-screen{
              width: 34%;
            }
            position: absolute;
            right: 17%;
            top: 14%;
            z-index: 1;
            border-top-left-radius: 51px;
            border-top-right-radius: 51px;
            box-shadow: 0 0 90px 12px rgba(0, 0, 0, 0.08);
          }
        }
        & form{
          width: 380px;
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 999;
          background-color: #FFFFFF;
          margin-left: -190px;
          margin-top: -116px;
          text-align: center;
          overflow: hidden;
          padding: 24px 40px;
          border-radius: 20px;
          box-shadow: 0 0 90px 12px rgba(0, 0, 0, 0.08);
          & h4{
            font-size: 22px;
            margin-top: 10px;
          }
          & .input-field{
            margin: 24px 0;
          }
          & input{
            width: 100%;
            box-sizing: border-box;
            max-width: 100%;
            border: 1px solid $blue;
            border-radius: 8px;
            padding: 10px 10px;
            line-height: 1;
            height: auto;
            font-size: 27px;
            letter-spacing: 3px;
            margin: 0;
            &:focus{
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
            }
          }
          & button{
            background: $blue;
            @include gb-blue;
            font-size: 22px;
            text-transform: capitalize;
            line-height: 2;
            height: auto;
            color: #FFFFFF;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            & img{
              float: right;
              width: 14px;
              height: 44px !important;
              display: block;
              margin-left: 8px;
            }
          }
        }
        & .contact{
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 99;
          background: #FFFFFF;
          overflow: hidden;
          padding: 20px 0;
          & h3{
            width: 58%;
            margin: 0;
            float: left;
            font-size: 30px;
            @include l-screen{
              font-size: 26px;
            }
          }
          & a.btn{
            background: $blue;
            @include gb-blue;
            padding: 10px 20px 10px 10px;
            text-transform: lowercase;
            box-shadow: none;
            line-height: 1.2;
            @include l-screen{
              line-height: 1.4;
            }
            border-radius: 30px;
            float: right;
            font-size: 30px;
            @include l-screen{
              font-size: 26px;
            }
            height: auto;
            font-family: $font_rubik_regular;
            & img{
              width: 38px;
              background: #fff;
              border-radius: 26px;
              border: 1px solid #fff;
              float: left;
              margin-right: 10px;
            }
          }
        }
      }
    }
    &.page{
      & .container{
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
        padding: 40px;
      }
      padding: 60px 0;
      min-height: 70vh;
      & .title{
        margin-bottom: 40px;
      }
    }
    &.subjects{
      padding: 60px 0;
      min-height: 70vh;
      & .container{
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
        padding: 40px;
      }
      & .title{
        margin-bottom: 20px;
        text-align: left;
        line-height: 42px;
        & ul{
          display: block;
          margin: 0px;
          float: right;
          overflow: hidden;
          border-radius: 24px;
          border: 1px solid $green;
          & li{
            float: left;
            &.active{
              background: $green;
              & a{
                color: #ffffff;
              }
            }
            & a{
              display: block;
              padding: 12px 14px;
              font-size: 16px;
              line-height: 1;
              color: #000;
            }
          }
        }
      }
      & .subtitle{
        font-size: 22px;
        color: #616161;
        margin: 40px 0 15px 0;
      }
      & article{
        display: flex;
        align-items: center;
        border: 2px solid #c6c6c6;
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 24px;
        & img{
          width: 100px;
          height: 100px;
          margin: 0 5px;
        }
        & div{
          flex: 1 1 auto;
          padding: 10px 15px;
          & h3{
            margin: 0;
            height: 26px;
            overflow: hidden;
            font-size: 24px;
            max-width: 100%;
            & a{
              color: #000;
            }
          }
          & h5{
            margin: 5px 0;
            color: #616161;
            font-size: 16px;
          }
          & p{
            margin: 0;
            font-size: 16px;
          }
        }
        & .btn{
          padding: 0 18px;
          color: #fff;
          margin: 0 15px;
          font-size: 24px;
          height: auto;
          line-height: 46px;
          background: $green;
          & img{
            float: left;
            width: 24px;
            height: 46px;
            margin-right: 15px;
          }
        }
        & aside{
          width: 210px;
          box-sizing: border-box;
          text-align: right;
          & .btn{
            text-align: left;
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            font-size: 20px;
            line-height: 38px;
            margin: 0;
            & + .btn{
              margin-top: 10px;
            }
            & img{
              height: 38px;
            }
          }
        }
      }
    }
    &.subject{
      padding: 30px 0 60px 0;
      & .title{
        margin-bottom: 40px;
      }
    }
    &.lessons{
      padding: 30px 0 50px 0;
      min-height: 70vh;
      position: relative;
      z-index: 1;
      overflow: hidden;
      display: flex;
      align-items: stretch;
      & .container{
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
        padding: 40px;
      }
      & .title{
        margin-bottom: 40px;
      }
      & .lesson{
        padding: 10px;
        overflow: hidden;
        margin-bottom: 15px;
        border: 1px solid $border_blue;
        border-radius: 5px;
        display: flex;
        & img{
          width: 20px;
          height: 20px;
        }
        & h2{
          font-family: $font_rubik_regular;
          font-size: 20px;
          line-height: 1;
          margin: 0;
          padding-left: 15px;
          flex: 1 1 auto;
          & a{
            display: block;
            color: #000000;
          }
        }
      }
      & .content{
        & h1{
          font-size: 32px;
          margin: 0 0 24px 0;
          & .btn{
            float: left;
            margin-right: 10px;
            background: #ffffff;
            border: 1px solid #001a65;
            color: #001a65;
            & img{
              height: 32px;
              float: left;
            }
          }
        }
        & .audio{
          margin-bottom: 24px;
          & span{
            padding-left: 15px;
            display: block;
          }
          & audio{
            width: 100%;
          }
        }
        & .video{
          & span{
            padding-left: 15px;
            display: block;
            text-align: center;
          }
          & video{
            display: block;
            margin: 0 auto;
          }
        }
        & .contents{
          text-align: justify;
          margin-bottom: 40px;
        }
      }
      & .pagination{
        & .btn{
          float: left;
          margin-right: 10px;
          background: #ffffff;
          border: 1px solid #001a65;
          color: #001a65;
          &.right{
            float: right;
            margin-right: 0;
            & img{
              margin-left: 10px;
              margin-right: 0;
              float: right;
            }
          }
          & img{
            height: 32px;
            float: left;
          }
        }
      }
    }
    & #comments{
      padding-top: 40px;
      margin-top: 40px;
      border-top: 1px solid #d6d6d6;
      & h4{
        font-size: 24px;
        margin: 0;
      }
      & .list{
        overflow: hidden;
        max-height: 70vh;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 24px 0;
        padding: 24px;
        border: 1px solid #d6d6d6;
        & p{
          $b_radius: 24px;
          display: block;
          width: 100%;
          margin: 0;
          padding: 5px 0;
          text-align: left;
          & span{
            display: inline-block;
            font-size: 16px;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
            max-width: 60%;
            padding: 8px 12px;
            border-radius: $b_radius;
          }
          &.right{
            text-align: right;
          }
        }
      }
      & textarea, & textarea:focus{
        padding: 15px 24px;
        border-radius: 24px;
        border: 1px solid #d6d6d6;
        outline: none;
        box-shadow: none;
      }
      & .btn{
        float: right;
      }
    }
    &.audiobooks{
      padding: 60px 0;
      & .container{
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
        padding: 40px;
      }
      & .title{
        margin-bottom: 50px;
      }
      & aside{
        box-sizing: border-box;
        padding: 24px;
        margin-bottom: 50px;
        display: flex;
        align-items: stretch;
        border-radius: 8px;
        border: 2px solid #c6c6c6;
        & img:first-child{
          width: 70px;
          height: 70px;
          display: block;
          margin-right: 24px;
        }
        & div{
          flex: 1 1 auto;
          & h2 {
            font-family: $font_regular_45;
            font-size: 24px;
            margin: 0;
            & a{
              color: #002879;
            }
          }
          & h6{
            display: block;
            font-family: $font_regular_45;
            font-size: 14px;
            color: #bbbbbb;
            margin: 0;
          }
        }
        & .btn{
          margin: 17px auto;
          &.btn-success{
            @include gb-green;
          }
          & img{
            width: 20px;
            height: 36px;
            margin-right: 10px;
            float: left;
          }
        }
        & span{
          position: absolute;
        }
      }
    }
    &.buy{
      padding: 60px 0;
      min-height: 70vh;
      & .title{
        margin-bottom: 20px;
      }
      & article{
        display: flex;
        justify-content: center;
        padding: 50px 40px;
        & form:not(#buy__audiobooks){
          width: 50%;
          box-shadow: 0 0 70px rgba(0, 0, 0, 0.04);
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0 40px;
          & .stars{
            display: flex;
            justify-content: space-around;
            position: relative;
            box-sizing: border-box;
            & img{
              width: 100%;
              height: 80px;
            }
          }
          & h2{
            width: 100%;
            font-size: 22px;
            text-align: center;
            margin-top: 24px;
            font-family: $font_rubik_medium;
            text-transform: uppercase;
          }
          & .info{
            margin-bottom: auto;
            & span{
              display: block;
              font-size: 16px;
            }
          }
          & .price{
            margin-bottom: 0;
            margin-top: 25px;
            text-align: center;
            width: 100%;
            text-transform: uppercase;
            font-family: $font_rubik_medium;
          }
          & select{
            height: 2.5rem;
            margin: 0 0 10px 0;
          }
          & .btn{
            display: block;
            margin: 15px auto 0 auto;
            background-image: linear-gradient(80deg, #662d91 0%, #00c9dd 51%, #662d91 100%);
            & img{
              height: 36px;
              float: left;
            }
          }
        }
      }
      & form#buy__audiobooks{
        display: flex;
        align-items: center;
        & .info{
          flex: 1 1 auto;
          & h2{
            margin: 0;
            font-size: 28px;
          }
          & p{
            margin: 0;
            font-size: 16px;
            color: rgba(69, 69, 69, 0.49);
          }
        }
        & .price{
          margin: 0 10px;
          font-size: 18px;
        }
        & select{
          width: 100px;
          height: auto;
          margin: 0 24px;
        }
        & .btn{
          width: 150px;
          & img{
            height: 36px;
            float: left;
          }
        }
      }
    }
    &.auth{
      padding: 50px 0;
      min-height: 70vh;
      display: flex;
      align-items: center;
      & .container{
        box-shadow: none;
        padding: 0;
      }
      & form{
        width: 35%;
        padding: 24px 32px;
        background: #FFFFFF;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
        margin: 0 auto;
        & h2{
          margin: 0 0 20px 0;
          text-align: center;
          font-size: 28px;
        }
        & .btn, .btn-flat{
          height: auto;
          line-height: 40px;
          font-size: 18px;
          text-transform: inherit;
          text-align: center;
        }
      }
      &.login{
        & .btn, .btn-flat {
          width: 64%;
        }
        & .btn{
          width: 34%;
        }
        & a:not(.btn-flat){
          display: block;
          padding: 15px 0 0 0;
          text-align: center;
        }
      }
      &.registration{
        & .btn, .btn-flat {
          width: 34%;
        }
        & .btn{
          width: 64%;
        }
      }
    }
    &.profile{
      padding: 50px 0;
      min-height: 70vh;
      $s_blue: #0035a0;
      & .container{
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
        padding: 40px;
      }
      & ul{
        border-bottom: 1px solid #dcdcdc;
        padding: 0;
        margin: 35px 0 15px 0;
        & li{
          float: left;
          border-bottom: 2px solid transparent;
          margin-bottom: -1px;
          &.active{
            border-color: $s_blue;
            & a{
              color: $s_blue;
            }
          }
          & a{
            display: block;
            padding: 10px 25px;
            color: #000000;
            font-size: 16px;
            &:hover{
              color: $s_blue;
            }
          }
        }
      }
      & .settings{
        padding: 24px 0;
        & input{
          border-color: $s_blue !important;
        }
        & label{
          color: $s_blue !important;
        }
      }
      & .subjects__list, & .audiobooks__list{
        & .item{
          padding: 15px;
          display: flex;
          background: rgba(0, 0, 0, 0.01);
          margin-bottom: 15px;
          & img{
            width: 80px;
            height: 80px;
            margin-right: 15px;
          }
          & h2{
            position: relative;
            font-size: 28px;
            margin: 0;
            flex: 1 1 auto;
            & a{
              color: #000000;
              display: block;
            }
            &[data-sub-name]{
              padding-top: 25px;
              &:before{
                content: attr(data-sub-name);
                position: absolute;
                top: 0;
                left: 0;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.36);
              }
            }
          }
        }
        & article{
          display: flex;
          align-items: center;
          border: 2px solid #c6c6c6;
          border-radius: 8px;
          padding: 15px;
          margin-bottom: 24px;
          & img{
            width: 100px;
            height: 100px;
            margin: 0 5px;
          }
          & div{
            flex: 1 1 auto;
            padding: 10px 15px;
            & h3{
              margin: 0;
              height: 26px;
              overflow: hidden;
              font-size: 24px;
              max-width: 100%;
              & a{
                color: #000;
              }
            }
            & h5{
              margin: 5px 0;
              color: #616161;
              font-size: 16px;
            }
            & p{
              margin: 0;
              font-size: 16px;
            }
          }
          & .btn{
            padding: 0 18px;
            color: #fff;
            margin: 0 15px;
            font-size: 24px;
            height: auto;
            line-height: 46px;
            background: $green;
            & img{
              float: left;
              width: 24px;
              height: 46px;
              margin-right: 15px;
            }
          }
        }
      }
    }
    &.error__page{
      min-height: 70vh;
      & .container{
        box-shadow: none;
      }
      & svg{
        width: 38%;
        margin: 10px auto 0 auto;
        display: block;
      }
    }
  }
  &.footer{
    padding: 24px 0;
    & h6{
      font-size: 18px;
      text-align: center;
      margin: 15px 0;
    }
  }
  & .input__rounded{
    & input, & select, & select{
      border: 1px solid $blue !important;
      padding: 0 20px;
      border-radius: 25px;
      height: auto;
      line-height: 2.8;
      z-index: 1;
      box-sizing: border-box;
      &:focus{
        box-shadow: none !important;
      }
    }
    & select{
      height: 46px;
      &:focus{
        outline: none !important;
      }
      & + label:after{
        position: absolute;
        left: 0;
      }
    }
    & label, & label.active{
      top: -9px;
      left: 20px;
      color: $blue !important;
      padding: 0 5px;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.5px;
      transform: none !important;
      transform-origin: inherit !important;
      z-index: 5;
      &:after{
        content: '';
        top: 9px !important;
        background: #fff !important;
        height: 1px;
        width: 100%;
        z-index: -1;
        opacity: 1 !important;
      }
    }
    &.col label{
      left: 31px;
    }
  }
  & .btn.btn-rounded{
    box-shadow: none;
    border-radius: 25px;
    @include gb-blue;
  }
  & .btn-flat.btn-rounded{
    box-shadow: none;
    border-radius: 25px;
  }
}


.pagination.center{
  & li{
    overflow: hidden;
    &.active{
      background: $blue;
    }
    &.prev, &.next{
      & span{
        display: block;
        padding: 0 10px;
        & img{
          display: block;
          width: 16px;
          height: 30px;
        }
      }
      & a{
        & img{
          display: block;
          width: 16px;
          height: 30px;
        }
      }
    }
  }
}
.breadcrumb-list{
  .breadcrumb{
    color: #9a9a9a;
    font-size: 14px;
    text-transform: uppercase;
    &:first-child{
      &:before{
        display: none !important;
      }
    }
    &:last-child{
      color: #9a9a9a;
    }
    &:before {
      content: '';
      display: inline-block;
      height: 13px;
      margin: 0 3px -1px 0;
      padding: 4px 10px;
      background: url(/img/chevron-right.svg) no-repeat center;
      vertical-align: baseline;
    }
  }
}
.btn.buy{
  margin: 0 auto 40px auto;
  line-height: 48px;
  height: auto;
  font-size: 24px;
  & img{
    width: 24px;
    height: 48px;
    display: block;
    float: left;
  }
}
.text-center{
  text-align: center;
}
.datepicker{
  background: #314048;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.datepicker--nav{
  max-height: 32px;
  box-sizing: border-box;
  background: #263238;
  padding: 0;
}
.datepicker--pointer{background: #263238;border: none;}
.datepicker--nav-title i{
  font-size: 16px !important;
  color: #fff;
}
.btlr{border-top-left-radius: 0 !important;}
.btrr{border-top-right-radius: 0 !important;}
.bblr{border-bottom-left-radius: 0 !important;}
.bbrr{border-bottom-right-radius: 0 !important;}
[class*=hint--]:after{
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
}
.noty_theme__metroui.noty_bar{
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
}
.noty_theme__metroui.noty_type__error{
  background-color: $red;
  //@include gb-red;
}
.noty_theme__metroui.noty_type__warning{
  background-color: $orange;
  //@include gb-orange;
}
.noty_theme__metroui.noty_type__info, .noty_theme__metroui.noty_type__information{
  background-color: $cyan;
  //@include gb-blue;
}
.noty_theme__metroui.noty_type__success{
  background-color: $green;
  //@include gb-green;
}
.noty_theme__metroui.noty_bar .noty_body{
  letter-spacing: 0.5px;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
@-webkit-keyframes fadeOutDownBig {
  from {opacity: 1;}
  to {opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes fadeOutDownBig {
  from {opacity: 1;}
  to {opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}



