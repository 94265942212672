@font-face {
  font-family: "CSR_45";
  src: url(../fonts/CoreSansE/CoreSansE-45Regular.otf);
}
@font-face {
  font-family: "CSM_55";
  src: url(../fonts/CoreSansE/CoreSansE-55Medium.otf);
}
@font-face {
  font-family: "R_R";
  src: url(../fonts/Rubik/Rubik-Regular.ttf);
}
@font-face {
  font-family: "R_M";
  src: url(../fonts/Rubik/Rubik-Medium.ttf);
}
/*
@font-face {
  font-family: "CSM_65";
  src: url(../fonts/CoreSansE/CoreSansE-65Bold.otf);
}
@font-face {
  font-family: "CSM_95";
  src: url(../fonts/CoreSansE/CoreSansE-95Black.otf);
}
@font-face {
  font-family: "CSL_35";
  src: url(../fonts/CoreSansE/CoreSansE-35Light.otf);
}
@font-face {
  font-family: "CSR_45";
  src: url(../fonts/CoreSansE/CoreSansE-45Regular.otf);
}*/
